.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: transparent;
  font-family: inherit;
  font-size: 18px;
  line-height: 1.33333333;
  font-weight: 600;
  cursor: pointer;
  color: #000;
  padding: 0 0 2px 0;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  background-size: 100% var(--generalBorderWidth);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #004093, #004093);
  transition: all 0.2s;
}
.button:hover,
.button:focus,
.unit--isAnchor:hover .button {
  background-size: 0 var(--generalBorderWidth);
}
.section--root .content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.layout3 .section--root .content,
.layout5 .section--root .content {
  flex-direction: column;
  align-items: center;
}
.section--root .subcontent.subcontent--1 {
  width: calc(100% - (580px + 24px));
}
.section--root .subcontent.subcontent--2 {
  width: calc(580px + var(--contentOverflow));
  margin-right: calc(var(--contentOverflow) * -1);
}
.layout3 .section--root .subcontent.subcontent--1,
.layout5 .section--root .subcontent.subcontent--1 {
  width: 100%;
  max-width: 864px;
}
.section--two .subcontent.subcontent--1 {
  width: calc(100% - (274px + 24px));
}
.section--two .subcontent.subcontent--2 {
  width: 274px;
}
.section--three .subcontent {
  width: calc(50% - 60px);
}
.area--one .unitFive {
  width: calc(25% - 24px);
}
.area--one .unitSix--wide {
  width: calc((100% - (580px + 24px + 24px)));
}
.area--one .unitSix--slim {
  width: 580px;
}
.area--twoA .unitOne--1-2 {
  width: calc(50% - 24px);
}
.area--four .unit {
  width: calc((100% / 3) - 24px);
}
.area--five .unitOne--1-3 {
  width: calc((100% / 3) - 24px);
}
/*# sourceMappingURL=./screen-large.css.map */